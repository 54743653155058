export const isNotDev = process.env.REACT_APP_ENV !== 'development';
const DOMAIN_URL = isNotDev
  ? (() => {
      // assuming we'll be running content and course servers as subdomains
      const { host } = window.location;
      const hostComponents = host.split('.');
      hostComponents.shift();

      return hostComponents.join('.');
    })()
  : 'dev.univ.ai';

const PROTOCOL = isNotDev ? 'https' : 'http';
const PROTOCOL_WS = isNotDev ? 'wss' : 'ws';

export const APP_BASEPATH = isNotDev ? process.env.PUBLIC_URL : window.location.origin;
export const APP_BASENAME = APP_BASEPATH;
export const COURSES_BASE_URL = APP_BASENAME;
export const CONTENT_URL = process.env.REACT_APP_CONTENT_URL;
export const APPLICATIONS_SITE_URL = process.env.REACT_APP_APPLICATIONS_BASE_URL;
export const MY_DASHBOARD_URL = `${PROTOCOL}://my.${DOMAIN_URL}`;

export const SUBMISSIONS_PUT_URL = `${PROTOCOL}://put.uploads.${DOMAIN_URL}`;
export const SUBMISSIONS_CONTROLLER_URL = `${PROTOCOL}://uploads.${DOMAIN_URL}/api/submissions`;
export const EXERCISE_URL = `${PROTOCOL}://exercise.${DOMAIN_URL}/app/exercise`;
export const AUTH_URL = `${PROTOCOL}://user.${DOMAIN_URL}`;
export const DISCOURSE_API_URL = process.env.REACT_APP_DISCOURSE_API_URL;
export const VIDEO_URL = isNotDev ? `${PROTOCOL}://static.${DOMAIN_URL}/api/serve` : CONTENT_URL;
export const TEST_URL = `${PROTOCOL}://test.${DOMAIN_URL}/app/exercise`;
export const HELP_URL = `${APPLICATIONS_SITE_URL}/help`;
export const USER_PROFILE_URL = `${MY_DASHBOARD_URL}/profile`;
export const SUBMISSIONS_TA_PATH = '/submissions/ta';
export const APPLICATIONS_PATH = '/applications';
export const LOGIN_PATH = '/login';
export const SCHEDULE_PATH = '/schedule';
export const APPLICATIONS_URL = `${APPLICATIONS_SITE_URL}${APPLICATIONS_PATH}`;
export const PRODUCTS_PATH = '/products';
export const NOTES_PATH = '/notes';
export const NOTIFICATION_PATH = '/notifications';
export const USER_APPLICATION_URL = `${APPLICATIONS_SITE_URL}/applications/latest`;
export const USER_CERTIFICATES_URL = `${APPLICATIONS_SITE_URL}/certificates`;
export const APPLICATION_PRODUCTS_URL = `${APPLICATIONS_SITE_URL}/applications/products`;
export const USER_COURSE_PATH = '/';
export const EVENTS_PATH = '/schedule';
export const LIVE_URL = '/live';
export const LIVE_EVENT_URL = '/live/event/';
export const LIVE_EVENT_CONNECT_URL = '/live/connect/';
export const LIVE_CLASS_PATH = '/class';

//Notification URI constants
export const NOTIFICATION_API_URL = `${PROTOCOL}://notification.${DOMAIN_URL}`;
export const NOTIFICATION_API_GET_MESSAGES = `${NOTIFICATION_API_URL}/get_notification`;
export const LIVE_EVENT_WS_URI = `${PROTOCOL_WS}://notification.${DOMAIN_URL}/subscribe`;
export const CLASS_EVENT_WS_URI = `${PROTOCOL_WS}://notification.${DOMAIN_URL}/subscribe-class`;
//User NOTES URI constants
export const GET_NOTE_URI = `${NOTIFICATION_API_URL}/get_user_note`;
export const INSERT_NEW_NOTE_URI = `${NOTIFICATION_API_URL}/insert_user_note`;
export const UPDATE_NOTE_URI = `${NOTIFICATION_API_URL}/update_user_note`;

export const getLoginURL = (redirectURL?: string) => `${APPLICATIONS_SITE_URL}/login?from=${redirectURL}`;
export const getLogoutURL = (redirectURL?: string) =>
  `${AUTH_URL}/logout${redirectURL ? `?redirect=${redirectURL}` : ''}`;
export const getAuthRefreshURL = (fromURL: string) => `${AUTH_URL}/jwt/refresh?from=${fromURL}`;

export const COURSE_API_URL = process.env.REACT_APP_API_URL;
export const EXERCISE_API_URL = process.env.REACT_APP_EXERCISE_API_URL;

//User Timetable URLS
export const USER_COURSE_TIMETABLE_URL = `${COURSE_API_URL}/user/course-timetable`;
export const USER_GET_CLASS_URL = `${COURSE_API_URL}/user/course-class`;
export const USER_GET_MEETUP_CLASS_URL = `${COURSE_API_URL}/meetup/class-detail`;
export const USER_GET_CLASS_BY_COURSE_URL = `${COURSE_API_URL}/class/get-class`;
export const TOTAL_UPCOMING_EVENTS = `${COURSE_API_URL}/user/upcoming-class-total`;
export const ADMIN_START_CLASS_URL = `${COURSE_API_URL}class/start-class`;
export const ADMIN_END_CLASS_URL = `${COURSE_API_URL}class/end-class`;
export const COURSE_API_WS_URL = COURSE_API_URL.replace('http', 'ws'); // ws or wss
export const USER_COURSES_URL = `${COURSE_API_URL}/user/courses`;
export const FREE_PRODUCTS_URL = `${COURSE_API_URL}/products/free`;
export const USER_COURSE_EVENTS_URL = `${COURSE_API_URL}/user/course-events`;
export const TOC_FILE_NAME = 'TOC.yaml';
export const INDEX_FILE = { name: 'README.md', type: 'md' };
export const SUMMARY_FILE = { name: 'SUMMARY.md', type: 'md' };
export const INVALID_TOKEN_MSG = 'Invalid token. Access denied.';
export const ACCESS_DENIED_MSG = 'Access denied.';
export const NO_CONTENT = 'No Content.';

// PROGRESS URLS
export const PROGRESS_URL_GET = `${NOTIFICATION_API_URL}/get_user_progress`;
export const PROGRESS_URL_UPSERT = `${NOTIFICATION_API_URL}/insert_user_progress`;

//Attendance URLS
export const ATTENDANCE_URL_GET = `${NOTIFICATION_API_URL}/get_attendance`;
//NB-PAD URLs
export const NBPAD_URL = `${PROTOCOL}://code.${DOMAIN_URL}`;
export const NBP_GET_NOTEBOOKS_URL = `${NBPAD_URL}/api/v1/check-existing-nbs`;
export const NBP_UPLOAD_NOTEBOOK_URL = `${NBPAD_URL}/api/v1/create-course-notebook`;
export const NB_EXEC_URL = `${PROTOCOL}://tljh.${DOMAIN_URL}/broker`;
export const TLJH_URL_HTTP =
  process.env.REACT_APP_ENV == 'staging' ? `${PROTOCOL}://tljh.univ.ai` : `${PROTOCOL}://tljh.${DOMAIN_URL}`;
export const TLJH_URL_WSS =
  process.env.REACT_APP_ENV == 'staging' ? `wss://tljh.univ.ai` : `wss://tljh.${DOMAIN_URL}`;

export const ZOOM_FCS_URL = `${PROTOCOL}://zoom.${DOMAIN_URL}`;
